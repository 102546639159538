@import '../../../styles/globals.css';
@import 'react-loading-skeleton/dist/skeleton.css';
@tailwind utilities;

:root {
  color-scheme: dark;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Aeonik-Regular', 'Helvetica', 'Arial', sans-serif;
  color: #cdcdcd;
  background-color: #000;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  height: 100%;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: square;
}

li {
  color: inherit;
}

/* Turn off spinners on number input fields -- webkit unique property*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@layer utilities {
  .cf-gray-gradient {
    background: linear-gradient(91.18deg, #d2d2d2 12.83%, #ffffff 50.15%, #9f9f9f 94.25%);

    @apply bg-clip-text text-transparent;
  }

  .bg-holy-radial-gray-2-30 {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.03) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #111111;
  }

  .bg-holy-radial-gray-3-60 {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #161616;
  }

  .bg-holy-radial-gray-4-30 {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.03) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #252525;
  }

  .bg-holy-radial-gray-4-60 {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #252525;
  }

  .bg-holy-radial-gray-5 {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.03) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #232323;
  }

  .bg-holy-radial-green-1 {
    background: radial-gradient(93.61% 93.61% at 50% 100.06%, #2e5444 0%, rgba(46, 84, 68, 0) 100%),
      #192723;
  }

  .bg-holy-radial-green-2 {
    background: radial-gradient(93.61% 93.61% at 50% 100.06%, #2e5444 0%, rgba(46, 84, 68, 0) 100%),
      #101a17;
  }

  .bg-holy-radial-pink-1 {
    background: radial-gradient(93.61% 93.61% at 50% 100.06%, #542e47 0%, rgba(84, 46, 67, 0) 100%),
      #1a1013;
  }

  .box-shadow-success-1 {
    box-shadow: 0px 4px 61px rgba(70, 218, 147, 0.06);
  }
  .bg-route-card-selected {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #1c1f1e;
  }

  .bg-route-card-selected-hovered {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #1f2321;
  }

  .bg-deposit-mode-card {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #161616;
  }

  .bg-deposit-mode-card-hover {
    background: radial-gradient(
        63.94% 63.94% at 50% 0%,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #1c1c1c;
  }
}

.silk-curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/bg.svg');
  background-image: url('/bg.svg'),
    radial-gradient(
      63.94% 63.94% at 50% 0%,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cf-boost-active-gradient {
  background: radial-gradient(
      100% 100% at 50% 155.94%,
      rgba(255, 175, 223, 0.18) 0%,
      rgba(255, 51, 175, 0) 100%
    ),
    radial-gradient(
      92.19% 92.19% at 50% -9.38%,
      rgba(255, 51, 175, 0.18) 0%,
      rgba(255, 51, 175, 0) 100%
    ),
    #161616;
}

/* Do not add extra width to the container when the scrollbar is visible */
.stable-scrollbar-gutter {
  scrollbar-gutter: stable;
}
